import {graphql, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Head from '../components/Head/Head.jsx'
import {Heading1} from '../components/Heading/Heading.jsx'
import {MarkdownSection} from '../components/MarkdownSection/MarkdownSection.jsx'
import Page from '../components/Page/Page.jsx'
import {contentfulMarkdownRemarkPropTypes, contentfulMetadataSeoPropTypes} from '../shared-prop-types.js'

const Terms = (props) => {
  const {content} = props

  return (
    <Page>
      <Head pageContent={content.metadataSEO}/>
      <Heading1>{content.metadataSEO.title}</Heading1>
      <MarkdownSection dangerouslySetInnerHTML={{__html: content.terms.childMarkdownRemark.html}}/>
    </Page>
  )
}

Terms.propTypes = {
  content: PropTypes.shape({
    metadataSEO: PropTypes.shape(contentfulMetadataSeoPropTypes).isRequired,
    terms: PropTypes.shape(contentfulMarkdownRemarkPropTypes).isRequired,
  }).isRequired,
}

const TermsWithContent = () => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query ContentfulPageTermsQuery {
        contentfulPageTerms {
          metadataSEO {
            canonicalURL
            description
            title
          }
          terms {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Terms content={data.contentfulPageTerms}/>}
  />
)

export default TermsWithContent
