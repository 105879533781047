import styled from 'styled-components'
import {colors} from '../../config/colors.js'

export const MarkdownSection = styled.section({
  maxWidth: '60rem',
  padding: '0 2rem 4rem 2rem',
  margin: '0 auto',
  // remove the margin-bottom from the last child of the section
  '*:last-child': {
    marginBottom: 'unset',
  },

  h1: {
    marginBottom: '2rem',
    fontSize: '2.4rem',
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  h2: {
    fontSize: '2rem',
  },

  p: {
    marginBottom: '1.5rem',
  },

  a: {
    color: colors['black-#000'],
    '&:hover': {
      backgroundColor: colors['black-#000'],
      color: colors['white-#ddd'],
    },
  },
})
